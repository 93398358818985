<template>
  <v-card class="mt-2 ml-2 mr-2">
    <!--    <v-toolbar-->
    <!--        flat-->
    <!--        light-->
    <!--    >-->
    <!--      <v-toolbar-title>{{ tabTitle }}</v-toolbar-title>-->
    <!--    </v-toolbar>-->

    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" :centered="$vuetify.breakpoint.smAndDown" color="deep-purple accent-4" background-color="#F5F5FF5F">
      <v-tab  class="justify-md-start" disabled>
        <v-icon left>
          mdi-cog-outline
        </v-icon>
        Options
      </v-tab>
      <v-tab class="justify-start" @click="tabUsersClick" active-class="">
        <v-icon left>
          mdi-account-multiple-outline
        </v-icon>
        Users
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Application Setting
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <UsersTable ref="refUsersTable"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FCompanyTable ref="refCompanyTable"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FDivisionTable ref="refDivisionTable"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FSalesmanTable ref="refSalesmanTable"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FWarehouseTable ref="refWarehouseTable"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

  </v-card>
</template>

<script>
import UsersTable from "../../components/setting/UsersTable";
import FCompanyTable from "../../components/setting/FCompanyTable";
import FDivisionTable from "../../components/setting/FDivisionTable";


export default {
  name: "Setting",
  components: {UsersTable, FCompanyTable, FDivisionTable},
  data() {
    return {
      tabTitle: 'Sistem Setting'
    }
  },
  methods: {
    tabUsersClick(){
      this.$refs.refUsersTable.fetchParent()
    },
    tabCompanyClick(){
      // this.$refs.refAsosiasiTable.fetchParent()
    },
    tabDivisionClick(){
      this.$refs.refDivisionTable.fetchParent()
    },
    tabSalesmanClick(){
      this.$refs.refSalesmanTable.fetchParent()
    },
  }
}
</script>

<style scoped>
</style>